import Header from "./components/Header";
import SectionOne from "./components/SectionOne";
import Review from "./components/Review";
import Forum from "./components/Forum";
import MapSection from "./components/MapSection";
import AboutMe from "./components/AboutMe";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div>
      <Header />
      <AboutMe />
      <SectionOne />
      <Review />
      <Forum />
      <MapSection />
      <Footer />
    </div>
  )
}

