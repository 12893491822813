import { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper';
import './index.css';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import FormReview from './modal';


export default function Review() {
  const [reviews, setreviews] = useState([]);


  useEffect(() => {
    (async () => {
      await axios.get('https://fataliev-server.na4u.ru/review')
        .then((res) => setreviews(res.data))
    })()
  }, []);

  return (
    <section className="testimonials" id="testimonials">
      <div className="container">
        <div className="testimonials-title">
          Отзывы
        </div>
        {/* <div className="testimonials-subtitle">
          <span>Отзывы</span>
        </div> */}
        <img src="image/plus.png" alt="Plus" className="medicine-plus" />
        <div className="review-slider">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={2}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            // pagination={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper">
            {reviews.length === 0
              ?
              <SwiperSlide>
                <div className="review-slider__profile">
                  Добавьте первый отзыв..
                </div>
              </SwiperSlide>
              : reviews.map((review, i) => (
                <SwiperSlide key={i}>
                  <div className="review-slider__profile">
                    <div className="review-slider__text">
                      {review.title}
                    </div>
                    <div className="review-slider__name">
                      — {review.user_name}
                      <span>
                        {review.position}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>))
            }
          </Swiper>
        </div>
        <FormReview setreviews={setreviews} reviews={reviews} />
      </div>
    </section>
  );
}
