import { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormReview({setreviews}) {
  const [value, setValue] = useState({
    title: '',
    user_name: '',
    position: 'Пациент',
  });
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddClose = () => {

    if (value.title != '') {
      ( async () => {
        await axios.post('https://fataliev-server.na4u.ru/review/addReview', value).then((res) => setreviews(res.data))
      })()
    }

    
    setValue({
      title: '',
      user_name: '',
      position: 'Пациент',
    })
    setOpen(false);
  };

  return (
    <div>
      <button 
        className="review-button btn"
        onClick={handleClickOpen}>Добавить отзыв
      </button>
      <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}>
        <DialogTitle>Добавить отзыв</DialogTitle>

        <DialogContent>
        <form className='form'>
          <input 
            value={value.user_name}
            onChange={(event) => setValue({ ...value, user_name: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Ваше имя..'/>
          <textarea 
            value={value.title} 
            onChange={(event) => setValue({ ...value, title: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Ваш отзыв..'/>
          <select
            className='form-input'
            value={value.position}
            onChange={(event) => setValue({ ...value, position: event.target.value })}>
            <option>Врач</option>
            <option>Пациент</option>
          </select>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleAddClose}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
