
import axios from 'axios';
import { useEffect, useState } from 'react';
import './index.css';
import FormQuestion from './modal';
import PostDetail from './postDetail';


export default function Forum() {

  const [posts, setPost] = useState([]);


  useEffect(() => {
    (async () => {
      await axios.get('https://fataliev-server.na4u.ru/post')
        .then((res) => setPost(res.data))
    })()
  }, []);

  return (
    <section className="questions" id="questions">
      <div className="container">
        <div className="question-title">
          Форум
        </div>
        {/* <div className="testimonials-subtitle">
          Вопросы наших <span>клиентов</span>
        </div> */}
        <div className="question-slider">
          {posts.length === 0
            ?
            <div className="question-slider__profile">
              Добавьте первый пост..
            </div>
            :
            posts.map((post) => (
              <div key={post._id} className="question-slider__profile">
                <div className="question-slider__profile-item">
                  <div className="question-slider__title">
                    {post.title}
                  </div>
                  <div className="question-slider__text">
                    {post.description}
                  </div>
                </div>
                <div className="question-slider__profile-item">
                  <PostDetail post={post} />
                  <span>ответов: 0</span>
                </div>
              </div>
            ))
          }
        </div>
        <FormQuestion setPost={setPost} />
      </div>
    </section>
  );
}
