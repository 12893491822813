import { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AboutMe() {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="main-header">
      <div className="container">
        <div className="offer d-flex">
          <div className="offer__info">
            <div className="offer__overhead">
              Здоровье -
            </div>
            <h1 className="offer__title">
              Самое Главное
            </h1>
            <div className="offer__subtitle">
              Доктор поможет вам почувствовать себя лучше <br /> и наслаждаться каждым днем вашей жизни.
            </div>
            <div>
              <button className="offer__btn parallax-btn btn"
                onClick={handleClickOpen}>Знакомство
              </button>
              <Dialog
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}>
                <DialogTitle>Знакомство</DialogTitle>
                <DialogContent>
                  <div>
                    <p className="offer__text">
                      Сосудистый хирург — медицинский специалист, занимающийся диагностикой и лечением патологий, связанных с нарушениями в функционировании вен и сосудов, с помощью хирургических, в т. ч. малоинвазивных методик.
                    </p>
                    <p className="offer__text">
                      Сердечно-сосудистые заболевания и их осложнения по-прежнему занимают первые позиции среди причин смертности взрослого, работоспособного населения России. В некоторых случаях они становятся причиной инвалидизации пациента и длительной утраты трудоспособности.
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <img src="image/doctor.png" alt="" className="doctor-img" />
        </div>
      </div>
    </div>
  );
}
