import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import axios from 'axios';
import { forwardRef, useEffect, useState } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostDetail({post}) {

  const [open, setOpen] = useState(false);
  const [openCom, setOpenCom] = useState(false);

  const [value, setValue] = useState({
      title: '',
      user_name: '',
      post_id: post._id,
  });
  const [comments, setComments] = useState([])

 

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenCom = () => {
    setOpenCom(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseCom = () => {
    setOpenCom(false);
  };
  



  const handleAddClose = () => {
    ( async () => {
      await axios.post('https://fataliev-server.na4u.ru/post/addComment', value)
    })()
    // setValue({
    //   title: '',
    //   user_name: '',
    // })
    setOpenCom(false);
  };


  useEffect(() => {
    (async() => {
      await axios.post('https://fataliev-server.na4u.ru/post/getCommentsOnPost', post._id)
        .then((res) => setComments(res.data))
      })()
  }, []);

  return (
    <>
      <button onClick={handleOpen}>Обсуждение</button>

      <Dialog 
        open={open} 
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'l'}
        sx={{ width: '100%' }}
        onClose={handleClose}>
        <DialogTitle sx={{textAlign: 'center'}}>{post.title}</DialogTitle>
        <DialogContent
          sx={{ height: '555px', overflowWrap: 'break-word' }}
        >
          <div className='post-title'>
            {post.description}
          </div>
          <div className='post-detail__wrapper'>
            {comments.map((com) => (
              <div key={com._id} className='post-detail__inner'>
                <div className="post-detail__user-name">
                  - {com.user_name}
                </div>
                <div className="post-detail__title">
                  {com.title}
                </div>
              </div>
            ))}
          </div>
        </DialogContent>

        <Dialog 
          open={openCom} 
          fullWidth={true}
          maxWidth={'sm'}
          onClose={handleClose}>
        <DialogTitle>Добавить отзыв</DialogTitle>

        <DialogContent>
        <form className='form'>
          <input 
            value={value.user_name}
            onChange={(event) => setValue({ ...value, user_name: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Ваше имя..'/>
          <input 
            value={value.title} 
            onChange={(event) => setValue({ ...value, title: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Тема поста..'/>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCom}>Отмена</Button>
          <Button onClick={handleAddClose}>Добавить</Button>
        </DialogActions>

        
      </Dialog>

        


        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button onClick={handleOpenCom}>Добавить комментарий</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
