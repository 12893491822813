import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';

export default function FormQuestion({setPost}) {
  const [value, setValue] = useState({
    title: '',
    user_name: '',
    description: '',
  });
 
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      title: '',
      user_name: '',
      description: '',
    })
    setOpen(false);
  };

  const handleAddPost = () => {
    ( async () => {
      await axios.post('https://fataliev-server.na4u.ru/post/addPost', value).then((res) => setPost(res.data))
    })()
    setValue({
      title: '',
      user_name: '',
      description: '',
    })
    setOpen(false);

  };

  return (
    <div>
      <button 
        className="question-button btn"
        onClick={handleOpen}>Добавить пост
      </button>

      <Dialog 
        open={open} 
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}>
        <DialogTitle>Добавить отзыв</DialogTitle>

        <DialogContent>
        <form className='form'>
          <input 
            value={value.user_name}
            onChange={(event) => setValue({ ...value, user_name: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Ваше имя..'/>
          <input 
            value={value.title} 
            onChange={(event) => setValue({ ...value, title: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Тема поста..'/>
          <textarea 
            value={value.description} 
            onChange={(event) => setValue({ ...value, description: event.target.value })}
            type='text'
            className='form-input'
            placeholder='Ваш вопрос..'/>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleAddPost}>Добавить</Button>
        </DialogActions>


      </Dialog>
    </div>
  );
}
